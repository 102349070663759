import React, { useState, useEffect, useContext } from 'react'
import PE_VC from '../../images/noun-investment-2180740_4/png/noun-investment-2180740.png';
import TF_US from '../../images/Shape_4/png/Shape.png';
import TF_INR from '../../images/noun-investment-2180740_2/png/noun-investment-2180740.png';
import Company from '../../images/noun-investment-2180740_5/png/noun-investment-2180740.png';
import axios from 'axios';
import { dashboardTopCardApi } from '../../Config/config';
import { UserContext } from '../../Contexts/UserContext';
import { Tooltip } from 'antd';

const Card = () => {
    const [dashboardTopData, setDashboardTopData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const {getToken} = useContext(UserContext);

    const getDashboardTopCard = () => {
        setIsLoading(true);
        axios.post(dashboardTopCardApi, {
        },{
            headers:{
                "Authorization": getToken()
            }
        }).then(response => {
            setIsLoading(false);
            if (response.status === 200) {
                setDashboardTopData(response.data)
            }
        })
    }

    useEffect(() => {
        getDashboardTopCard();
    },[])

    const addCommasToNumber = (number) => {
        return Math.round(number)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const addCommasToDecimalNumber = (number) => {
        return number?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    
    return (
        <div className="mt-14 w-full h-full">
            <div className="flex items-stretch bg-[#EDEDED] relative overflow-x-auto">
                {/* Cards container */}
                <div className="flex w-full p-4 space-x-4 min-w-[1200px]">
                    <div
                        className="relative bg-white text-[#A5711A] flex items-center justify-center px-8 py-3 font-medium flex-none w-[8%]"
                        style={{
                            clipPath:
                                "polygon(0 0, 85% 0, 92% 30%, 100% 50%, 92% 70%, 85% 100%, 0 100%, 8% 70%, 12% 50%, 8% 30%)",
                        }}
                    >
                        Alltime Figures
                    </div>

                    {/* <Tooltip title="All year data"> */}
                        <section className="bg-white rounded-md border border-gray-200 p-3 flex-none w-[30%]">
                            <div className="flex justify-between items-center">
                                <div className="flex-1">
                                    <div className="text-[13px] leading-[16px] text-[#2B2A29] mb-1">PE-VC</div>
                                    {isLoading ? (
                                        <span className="inline-block w-4 h-4 border-2 border-[#CA8A24] border-t-transparent rounded-full animate-spin" />
                                    ) : (
                                        <div className="text-[16px] leading-[23px] font-bold text-[#A5711A]">
                                            $ {addCommasToNumber(dashboardTopData?.pe_amount_usd)} B / {addCommasToNumber(dashboardTopData?.pe_total_companies)} Cos / {addCommasToNumber(dashboardTopData?.pe_total_deals)} Deals
                                        </div>
                                    )}
                                </div>
                                <img className="h-6 w-8 ml-4" src={PE_VC} alt="PE-VC icon" />
                            </div>
                        </section>
                    {/* </Tooltip> */}

                    {/* <Tooltip title="All year data"> */}
                        <section className="bg-white rounded-md border border-gray-200 p-3 flex-none w-[22%]">
                            <div className="flex justify-between items-center">
                                <div className="flex-1">
                                    <div className="text-[13px] leading-[16px] text-[#2B2A29] mb-1">PE-RE</div>
                                    {isLoading ? (
                                        <span className="inline-block w-4 h-4 border-2 border-[#CA8A24] border-t-transparent rounded-full animate-spin" />
                                    ) : (
                                        <div className="text-[16px] leading-[23px] font-bold text-[#A5711A]">
                                            $ {addCommasToDecimalNumber(dashboardTopData?.re_amount_usd)} B / {addCommasToNumber(dashboardTopData?.re_total_companies)} Deals
                                        </div>
                                    )}
                                </div>
                                <img className="h-6 w-7 ml-4" src={TF_US} alt="PE-RE icon" />
                            </div>
                        </section>
                    {/* </Tooltip> */}

                    {/* <Tooltip title="All year data"> */}
                        <section className="bg-white rounded-md border border-gray-200 p-3 flex-none w-[22%]">
                            <div className="flex justify-between items-center">
                                <div className="flex-1">
                                    <div className="text-[13px] leading-[16px] text-[#2B2A29] mb-1">M&A</div>
                                    {isLoading ? (
                                        <span className="inline-block w-4 h-4 border-2 border-[#CA8A24] border-t-transparent rounded-full animate-spin" />
                                    ) : (
                                        <div className="text-[16px] leading-[23px] font-bold text-[#A5711A]">
                                            $ {addCommasToDecimalNumber(dashboardTopData?.ma_amount_usd)} B / {addCommasToNumber(dashboardTopData?.ma_total_companies)} Cos
                                        </div>
                                    )}
                                </div>
                                <img className="h-6 w-7 ml-4" src={TF_INR} alt="M&A icon" />
                            </div>
                        </section>
                    {/* </Tooltip> */}

                    {/* <Tooltip title="Total no.of companies"> */}
                        <section className="bg-white rounded-md border border-gray-200 p-3 flex-none w-[13%]">
                            <div className="flex justify-between items-center">
                                <div className="flex-1">
                                    <div className="text-[13px] leading-[16px] text-[#2B2A29] mb-1">CFS</div>
                                    {isLoading ? (
                                        <span className="inline-block w-4 h-4 border-2 border-[#CA8A24] border-t-transparent rounded-full animate-spin" />
                                    ) : (
                                        <div className="text-[16px] leading-[23px] font-bold text-[#A5711A]">
                                            {addCommasToNumber(dashboardTopData?.cfs_total_companies)} Cos
                                        </div>
                                    )}
                                </div>
                                <img className="h-6 w-6 ml-4" src={Company} alt="CFS icon" />
                            </div>
                        </section>
                    {/* </Tooltip> */}
                </div>
            </div>
        </div>
    );
};

export default Card